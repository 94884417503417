import axiosClient from './axiosClient';
import prismClient from './prismClient';

const request = async (baseURL: string) => {
  const api = axiosClient.init(baseURL, null);

	const prism: any = await prismClient.init();

	const response = await api.post('/auth/request', {
		Ipk: prism.Ipk,
	});

	let verificationString = response.data.verificationString;
	let serverPublicKey = response.data.serverPublicKey;

	const authBox = prism.authenticatedAsymetricEncrypt(
		{
			verificationString: verificationString,
		},
		serverPublicKey
	);

	return {
		cipher: authBox.cipher,
		nonce: authBox.nonce,
	};
};

const verify = async (cipher: any, nonce: any, baseURL: string) => {
  const api = axiosClient.init(baseURL, null);
	const prism: any = await prismClient.init();

	const response = await api.post('/auth/verify', {
		Ipk: prism.Ipk,
		cipher: cipher,
		nonce: nonce,
	});

	return response.data.access_token;
};

const exportVal: any = {
	request: request,
	verify: verify,
};

export default exportVal;
